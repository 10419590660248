import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, get  } from "firebase/database";
import { getStorage, listAll, getDownloadURL } from 'firebase/storage';
import 'firebase/auth'
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyCU9bXg1MFKy7Faps6lhME--JPYosPx8Yc",
  authDomain: "sunarcpowerengineers-01.firebaseapp.com",
  projectId: "sunarcpowerengineers-01",
  storageBucket: "gs://sunarcpowerengineers-01.appspot.com",
  messagingSenderId: "927443171020",
  appId: "1:927443171020:web:a08a90a30a0afbc08eb469",
  measurementId: "G-1Y4FT22JJJ",
  databaseURL: "https://sunarcpowerengineers-01-default-rtdb.asia-southeast1.firebasedatabase.app/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, database, storage, auth , onValue, ref, get, db,collection, getDocs,listAll, getDownloadURL };
