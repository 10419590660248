import React from 'react';
import img from '../images/inner_working.png';
import { Link } from 'react-router-dom';
import './Navbar/style.css'

const Intro = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='work' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h3 className="text-3xl   font-bold">How <span style={{color:"#2095A1"}} >Solar</span> Works</h3>
                            <div>
                            <ul className='list-disc pl-6 my-3 text-s  font-regular'>
                                <li><span className='text-s  font-bold ' style={{color:"#2095A1"}}>SOLAR PANELS </span> Solar panels convert sunlight into DC electricity.</li>
                                <li><span className='text-s  font-bold ' style={{color:"#2095A1"}}>INVERTER </span>The inverter converts solar DC power into 240V alternating current (AC) suitable for your households appliances.</li>
                                <li><span className='text-s  font-bold ' style={{color:"#2095A1"}}>METER </span>Meter measures your eletricity production and consumption organically innovation.</li>
                                <li><span className='text-s  font-bold ' style={{color:"#2095A1"}}>HOME </span>Your home uses eletricity firstly from solar panels with additional demand supplied from grid.</li>
                                <li><span className='text-s  font-bold ' style={{color:"#2095A1"}}>NETWORK GRID </span> Any excess power is fed into electricity grid.</li>
                                <li><span className='text-s  font-bold ' style={{color:"#2095A1"}}>GARDEN </span>Bring to the table win-win survival strategies to ensure proactive domination, going forward.</li>
                            </ul>
                            </div>
                            <Link to="/contact" className="text-white bg-orange-500 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Contact us
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={img} />
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Intro;