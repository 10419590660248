import React from 'react';
import img from '../images/monocrystalline.jpeg';
import img2 from '../images/polycrystalline.jpeg';
import img3 from '../images/byfacial_1.png';
import img4 from '../images/consultation.svg';

const Services = () => {

    return (
        <div id="servicespannel" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        {/* <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Solar Pannels we recommended</h2> */}

                        {/* <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div> */}
                        {/* <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Off-grid solar power system for domastic and commercial</h2> */}
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-black rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Monocrystalline Panel</h2>
                                    <p className="text-md font-medium">
                                    Monocrystalline solar panels are renowned for their high efficiency and sleek design, making them a popular choice for residential and commercial solar installations. These panels are made from single-crystal silicon, giving them a uniform appearance and superior energy conversion capabilities.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-black rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Polycrystalline Panel</h2>
                                    <p className="text-md font-medium">
                                    Polycrystalline solar panels are a popular choice for solar energy systems due to their cost-effectiveness and reliable performance. These panels are made from silicon crystals that are melted together, creating a textured appearance on the surface.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-black rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Byfacial Monocrystalline</h2>
                                    <p className="text-md font-medium">
                                    Thin-film solar cells, also known as TFSC, are a type of photovoltaic technology that offers flexibility and versatility in solar panel design. Unlike traditional crystalline silicon solar cells, thin-film cells are made by depositing thin layers of photovoltaic materials onto a substrate, such as glass, metal, or plastic.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
            </section>


        </div>
    )
}

export default Services;