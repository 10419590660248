import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import ServicesPannel from '../components/ServicesPannel'
import Works from '../components/Works'
import Byfacial from '../components/Byfacial'
import Blog from '../components/blog/Blog';
import Calculator from '../components/Calculator'
import './index.css'


const Home = () => {
    return (
        < >
            <div style={{overflow:'hidden'}}>
            <Hero />
            <ServicesPannel />
            <Calculator />
            <Byfacial />
            <Works />
            <Intro />
            <Services />
            <Portfolio />
            <Clients />
            <Cta />
            <Footer />
        </div>
        </>

    )
}

export default Home;

