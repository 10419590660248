import React, { useEffect, useState } from 'react';
import './BlogCard.css'; // Import your CSS file for styling
import NavBar from '../Navbar/NavBar';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { database, ref, get, child } from '../../firebase'; // Adjust the import according to your file structure

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogRef = ref(database, 'blogs');
      const snapshot = await get(blogRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const blogsArray = Object.keys(data).map(key => data[key]);
        setBlogs(blogsArray);
      } else {
        console.log("No data available");
      }
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <NavBar />
      <div style={{ marginTop: '120px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h1 style={{ justifyContent: 'center', textAlign: 'center', background: '#2095A1', fontSize: '50px', padding: '25px', margin: '5px', color: 'white', borderRadius: '5px' }}>
                Blog
              </h1>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className='newsContainer' style={{ marginTop: '10px' }}>
        {blogs.length > 0 ? (
          blogs.map((blogs, index) => (
            <div key={index} className="newsCard">
              <img
                alt={blogs.title}
                src={blogs.imageUrl || 'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png'}
                className="newsImage"
              />
              <div className="newsText">
                <div>
                  <span className="title">{blogs.title}</span>
                  <br />
                  <span className="author">
                    <a href={blogs.authorLink || '#'} target="_blank" rel="noopener noreferrer">
                      <b>{blogs.authorShort}</b>
                    </a>
                    <span className="muted">
                      {` by ${blogs.author || 'Unknown Author'}`}
                    </span>
                  </span>
                </div>
                <div className="lowerNewsText">
                  <div className="description">{blogs.content}</div>
                  <span className="readmore">
                    read more at{' '}
                    <a href={blogs.sourceLink || '#'} target="_blank" rel="noopener noreferrer">
                      <b>{blogs.source || 'Unknown Source'}</b>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No blogs available</div>
        )}
      </div>
    </>
  );
};

export default Blog;
