import React, { useEffect, useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage'; // Updated import for Firebase Storage
import '../../src/index.css';

const Clients = () => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const storage = getStorage(); // Initialize Firebase Storage
        const imagesFolderRef = ref(storage, 'partner'); // Replace 'path_to_your_folder' with your folder path
        const imageList = await listAll(imagesFolderRef);

        const urls = await Promise.all(
          imageList.items.map(async (itemRef) => {
            const imageUrl = await getDownloadURL(itemRef);
            return imageUrl;
          })
        );

        setImageUrls(urls);
        console.log('Image URLs:', urls);
      } catch (error) {
        console.error('Error fetching image URLs:', error.message);
      }
    };

    fetchImageUrls();
  }, []);

  return (
    <div className="mt-8 bg-gray-100">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl uppercase font-bold" style={{ color: '#2095A1' }}>Our Clients</h2>
        </div>
        <div className="p-16" data-aos="fade-in" data-aos-delay="600">
          <ImageList sx={{ width: '100%', height: 450 }} cols={8} rowHeight={164} style={{ display: 'flex', justifyContent: 'center' }}>
            {imageUrls.map((url, index) => (
              <ImageListItem key={index}>
                <img src={url} className='partnerImage' alt={`Client ${index + 1}`} />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </section>
     
    </div>
  );
};

export default Clients;
