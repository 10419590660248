import * as React from 'react';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { database, storage } from '../../firebase'; // Correct import path
import { ref as databaseRef, set } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL,ref, uploadBytesResumable } from 'firebase/storage';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function FormPropsTextFields() {
  const [blogData, setBlogData] = React.useState({
    title: '',
    author: '',
    source: '',
    content: '',
  });
  const [image, setImage] = React.useState(null);

  const handleChange = (e) => {
    setBlogData({
      ...blogData,
      [e.target.id]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    try {
      console.log('Submitting blog data:', blogData);

      let imageUrl = '';
      if (image) {
        const imageRef = storageRef(storage, `images/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
        console.log('Image uploaded, URL:', imageUrl);
      }

      const blogRef = databaseRef(database, 'blogs/' + blogData.title.replace(/\s+/g, '_')); // Using title as key, sanitized
      await set(blogRef, {
        ...blogData,
        imageUrl,
      });

      alert('Blog updated successfully!');

      // Clear the input fields
      setBlogData({
        title: '',
        author: '',
        source: '',
        content: '',
      });
      setImage(null);
    } catch (error) {
      console.error('Error updating blog:', error);
      alert('Error updating blog');
    }
  };

    const handleSubmit_partner = async () => {
    try {
      let imageUrl = '';
      if (image) {
        setIsLoading(true);
        const imageRef = storageRef(storage, `partner/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);

      }
      alert('Blog updated successfully!');

      // Clear the input fields
      setImage(null);
      setIsLoading(false);
    } catch (error) {
      alert('Error updating blog');
    }
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

const handleUpload = async () => {
    if (image) {
      const imageRef = ref(storage, `partner/${image.name}`);
      const uploadTask = uploadBytesResumable(imageRef, image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error('Error uploading image:', error);
          alert('Error uploading image');
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('File available at', downloadURL);
          alert('Image uploaded successfully!');
          setImage(null); // Clear the input field
        }
      );
    }
  };

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div style={{ border: '1px solid gray', borderRadius: '5px', padding: '25px', margin: '10px', textAlign: 'center' }}>
        <h1 style={{ fontSize: '20px', margin: '10px' }}>Update Blog </h1>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            required
            id="title"
            label="Blog Title"
            value={blogData.title}
            onChange={handleChange}
          />
          <TextField
            required
            id="author"
            label="Author"
            value={blogData.author}
            onChange={handleChange}
          />
          <TextField
            required
            id="source"
            label="Source"
            value={blogData.source}
            onChange={handleChange}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            id="content"
            label="Multiline"
            multiline
            rows={4}
            value={blogData.content}
            onChange={handleChange}
            style={{ width: '60%', justifyContent: 'center' }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload image
            <VisuallyHiddenInput type="file" onChange={handleImageChange} />
          </Button>
          <Button style={{ marginLeft: '10px' }} variant="outlined" size="large" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>

      <div style={{ border: '1px solid gray', borderRadius: '5px', padding: '25px', margin: '10px', textAlign: 'center' }}>
        <h1 style={{ fontSize: '20px', margin: '10px' }}>Update Your partners </h1>
        <p>Select Image and press Save</p>
        <div>
      <Button
  component="label"
  role={undefined}
  variant="contained"
  tabIndex={-1}
  startIcon={<CloudUploadIcon />}
>
  Upload Partner Image
  <VisuallyHiddenInput type="file" onChange={handleImageChange}/>
</Button>
      <Button style={{ marginLeft: '10px' }} variant="outlined" size="large" onClick={handleSubmit_partner}>
            Submit
          </Button>
          {isLoading && (
        <Box sx={{ display: 'flex', justifyContent:'center' }}>
          <CircularProgress  />
        </Box>
      )}
    </div>
      </div>
    </Box>
  );
}
