import React, { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL, deleteObject } from 'firebase/storage';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { storage } from '../../firebase'; // Adjust the path as needed

const StandardImageList = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = ref(storage, 'partner');
      const imagesList = await listAll(storageRef);

      const urls = await Promise.all(
        imagesList.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return { img: url, ref: itemRef };
        })
      );

      setImages(urls);
    };

    fetchImages();
  }, []);

  const handleDelete = async (imgRef) => {
    try {
      await deleteObject(imgRef);
      setImages(images.filter((item) => item.ref !== imgRef));
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <div className='' style={{ margin: '10px', border: '1px solid grey', display: 'flex', justifyContent: 'center', borderRadius: '5px' }}>
      <ImageList sx={{ width: "80%", height: 450 }} cols={5} rowHeight={164}>
        {images.map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              alt=""
              loading="lazy"
              style={{ padding: '5px', margin: '5px' }}
            />
            <IconButton
              aria-label="delete"
              onClick={() => handleDelete(item.ref)}
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              <DeleteIcon />
            </IconButton>
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export default StandardImageList;
