import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function BasicTextFields() {
  const [consumption, setConsumption] = useState('');
  const [avgPrice, setAvgPrice] = useState('');
  const [result, setResult] = useState('');

  const handleCalculate = () => {
    let a, x, y,v,r, finalResult;

    if (consumption && !avgPrice) {
      a = parseFloat(consumption) * 12;
      x = a / 8;
      y = x / 30;
      finalResult = y / 4.5;

      // Round the finalResult based on the decimal part
      const decimalPart = finalResult % 1;
      if (decimalPart <= 0.5) {
        finalResult = Math.floor(finalResult);
      } else {
        finalResult = Math.ceil(finalResult);
      }
    } else if (!consumption && avgPrice) {
      a = parseFloat(avgPrice) * 12;
      x = a / 10;
      v = x / 8;
      r = v / 30;
      finalResult = r / 5;
      const decimalPart = finalResult % 1;
      if (decimalPart <= 0.5) {
        finalResult = Math.floor(finalResult);
      } else {
        finalResult = Math.ceil(finalResult);
      }
    } else {
      alert('Please enter either Consumption(Units) or Avg Price, not both.');
      return;
    }

    setResult(finalResult);
  };


  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div style={{justifyContent:'center', textAlign:'center',width:'100vw', padding:'25px' }}>
        <h1 style={{padding:'25px',color:'#2095A1'}} className="font-bold text-center lg:text-left  uppercase text-4xl" >CALCULATE YOUR REQUIRNMENT</h1>
      <div style={{ justifyContent: 'center', textAlign: 'center', width:'95vw' }}>
        <div style={{display:'flex',justifyContent:'center', textAlign:'center'}}>
        <TextField
          id="consumption"
          label="Consumption (Units)"
          variant="outlined"
          value={consumption}
           style={{margin:'25px'}}
          onChange={(e) => {
            setConsumption(e.target.value);
            setAvgPrice(''); // Clear Avg Price when Consumption is entered
          }}
        />
        <h1  style={{padding:'10px', alignItems:'center'}}>Or</h1>
        <TextField
          id="avg-price"
          label="Avg Price"
          variant="outlined"
          value={avgPrice}
           style={{margin:'25px'}}
          onChange={(e) => {
            setAvgPrice(e.target.value);
            setConsumption(''); // Clear Consumption when Avg Price is entered
          }}
        />
        </div>
        <Button variant="outlined" onClick={handleCalculate}  style={{padding:'25px', width:'400px'}}>
          Calculate
        </Button>

        {result && (
          <Typography variant="h6" style={{ margin: '20px', border:'1px solid #2095A1', borderRadius:'10px', padding:'10px', fontSize:'30px', fontWeight:'900', background:'#2095A1', color:'white' }}>
            Solar Requirnment: {result} Kw
          </Typography>
        )}
      </div>
      </div>
    </Box>
  );
}
