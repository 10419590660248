import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/web-dev.svg';
import Landing from '../images/landing.png';
import Landing1 from '../images/led3.png';
import './Navbar/style.css';

const Hero = () => {
    const [service, setService] = useState('solar'); // State to track the selected service

    const handleServiceChange = (selectedService) => {
        setService(selectedService); // Update the selected service
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setService(prevService => prevService === 'solar' ? 'led' : 'solar');
        }, 3000); // Change every 3 seconds

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                    <div id='hero' className="flex flex-col lg:flex-row py-8 margintop_l lg:text-left" style={{ padding:'20px', justifyContent:'center'}}>
                        <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
                            <a href="">
                                <img
                                    alt="card img"
                                    className="rounded-t float-right duration-1000 w-full"
                                    src={service === 'solar' ? Landing : Landing1} // Change image based on selected service
                                />
                            </a>
                        </div>
                        <div className="lg:w-1/2 flex flex-col justify-center lg:text-right text-center " data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold " style={{color:'#2095A1'}}>
                                {service === 'solar' ? 'Empowering Tomorrow: Solar Panel Installation Services' : 'Illuminate Your Space with LED Bulbs'}
                            </h1>
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                                {service === 'solar' ? 'Harnessing Clean Energy for a Sustainable Future' : 'Discover Our Range of Energy-Efficient Lighting Solutions'}
                            </div>
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                <Link to="/contact" className="text-white bg-blue-900 bg-orange-500 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Get Quote
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </Link>
                                <a href="https://wa.me/+919028337637/?text=Your%20custom%20message%20here" className="text-white bg-blue-900 bg-WA inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Whats app
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='solar' onClick={() => handleServiceChange('solar')}>Solar</button>
                <button className='led' onClick={() => handleServiceChange('led')}>LED</button>
            </div>
        </>
    )
}

export default Hero;
