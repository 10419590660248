import React from 'react';
import img from '../images/Installation.png';
import img2 from '../images/LED.png';
import img3 from '../images/maintaince.png';
import img4 from '../images/monitor_1.png';
import led1 from '../images/rope.png'
import led2 from '../images/exterior.png'
import led3 from '../images/interior.png'
import led4 from '../images/buld.png'
import led5 from '../images/spot.png'
import led6 from '../images/pannel.png'

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl  uppercase font-bold" style={{color:"#2095A1"}}>services</h2>

                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-[#2095A1]' style={{color:"#2095A1"}}></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-black-900">We are deeply committed to the growth and success of our clients.</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-black rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Solar Installation</h2>
                                    <p className="text-md font-medium">
                                        We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-black rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">LED bulb Manufacturing</h2>
                                    <p className="text-md font-medium">
                                    We provide LED bulbs ranging from 0.5W to 50W for home and industrial use. Pop panels, decorative lights, street lights, flood lights, and spot lights for both interior and exterior applications are also available.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-black rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Solar Panel Maintenance</h2>
                                    <p className="text-md font-medium">
                                        We provide domain registration and web hosting services to individuals and organizations to enable them gain visibility in the digital space.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-black rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Automation Solution</h2>
                                    <p className="text-md font-medium">
                                    Our Automation Solutions for Home and Industries provide expert guidance and support for businesses and individuals looking to optimize their operational efficiency and convenience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
{/*==================================footer=================================*/}
            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-center" data-aos="zoom-out">
                        <div className="lg:w-full flex flex-col lg:mx-4 justify-center">

                            <h3 className="text-3xl
                            font-bold">Verticals in  <span style={{color:"#2095A1"}}>LED</span></h3>
                            <div>
                                <p className='my-3 text-m  font-regular'>
                                We are in development of large verticals in LED which includes extensive range of interior and exterior lighting to meet the needs of diverse industries.
                                </p>
                            </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={led6} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Panel LED</h2>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={led5} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Spot light LED</h2>

                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={led4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">LED bulb</h2>

                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={led3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Interior Decoration LED</h2>

                                </div>
                            </div>
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={led2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Exterior LED</h2>

                                </div>
                            </div>
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={led1} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Rope LED</h2>

                                </div>
                            </div>
                        </div>
                    </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;