import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { database, ref as databaseRef, onValue } from '../../firebase'; // Import Firebase database modules


export default function BasicTable() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const contactsRef = databaseRef(database, 'contacts');

    onValue(contactsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messages = Object.values(data);
        setRows(messages);
      }
    });
  }, []);

  return (
    <div className='container' style={{padding:'20px'}}>
      <div>
          <h1 style={{fontSize:'20px', fontWeight:'900', margin:'25px', textAlign:'center'}}>Message</h1>
      </div>
      <div>
        <TableContainer component={Paper} style={{ maxHeight: "50Vh", overflowY: 'auto', background:'#A4D4DA' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow >
                <TableCell style={{ fontWeight: 'bold', fontSize: '20px'}} >First Name</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '20px'}} align="right">Last Name</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '20px'}} align="right">Email</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '20px'}} align="right">Phone No.</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '20px'}} align="center">Date</TableCell>
                <TableCell style={{ fontWeight: 'bold', fontSize: '20px'}} align="left">Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.firstName}
                  </TableCell>
                  <TableCell align="right">{row.lastName}</TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.phone}</TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                  <TableCell  align="left">{row.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
