import React from 'react';
import { Link } from 'react-router-dom';
import '../index.css'

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                {/* <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">More about us</h2> */}


                <div className="px-4 " data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-1 gap-5 ">


                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden clint text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Our Story</h4>
                                <p className="text-center">
                                We are proud to be a part of this industry as we provide innovative, cost-efficient, sustainable, and dependable lighting and solar solutions.
                                </p>
                                <p className="text-center my-3 px-5 ">
                                Starting in 2019, we began our journey with solar installation and maintenance services. With a focus on sustainability and energy efficiency, we provided solar PV modules, on-grid and off-grid inverters, and solar pumps for both new systems and upgrades to existing systems. Our commitment to quality and reliability ensured that customers could trust in the functionality and efficiency of their solar systems. Real-time monitoring and comprehensive maintenance through Annual Maintenance Programs (AMP) allowed our clients to enjoy peace of mind.
                                </p>
                                <p className="text-center my-3 px-5">
                                In 2023, we expanded into the LED lighting industry as a small startup with a dedicated team of competent professionals. Today, we have grown significantly to meet the needs of both residential and commercial establishments. Our extensive range of interior and exterior lighting solutions caters to diverse industries.
                                </p>
                                <p className="text-center my-3 px-5">
                                Our products are known for their rugged hardware, engineered ICs, bright LED chips, and well-designed casings, making them ideal for external lighting. For interior lighting, we offer attractive, compact luminaries with efficient drivers and LED chips that provide both efficiency and aesthetic appeal.
                                </p>
                                <p className="text-center my-3 px-5">
                                Furthermore, our smart lighting solutions save energy and offer full operative control remotely. These systems can be operated by remote, mobile applications, or sensors that detect presence, absence, or physical activity. We design these automatic systems to meet individual or application-specific requirements.
                                </p>
                                <p className="text-center my-3 px-5">
                                Every project starts with understanding and developing a complete engineered system to produce accurate, reliable, and cost-effective results for consumers, whether they experience zero power cuts or frequent outages. Our dedicated team of qualified and experienced experts makes this possible.
                                </p>
                                <p className="text-center my-3 px-5">
                                In addition to solar and lighting solutions, we offer custom lighting solutions designed for individual applications or requirements. We also provide automation for remotely operated, mobile-operated, or sensor-operated doors, motors, fans, or any other equipment. Our complete range of customized inverters with battery backup serves both residential and commercial applications.
                                </p>


                                {/* <ul className="border-t border-gray-300 py-8 space-y-6">
                                    <li className="flex items-center space-x-2 px-8">
                                        <span className="bg-blue-600 rounded-full p-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </span>
                                        <span className="text-gray-600 capitalize">Free Setup Guidance</span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;